<template>
    <div class="box">
		<myBreadcrumb :nav="nav"></myBreadcrumb>
        <div class="title">{{ data.volunteerTitle || '' }}</div>
        <div class="time">
            <span>{{ data.createTime || '' }}</span>
            <span>访问量：{{ data.readCount || 0 }}</span>
        </div>
        <div style='white-space: pre-wrap;' v-html="data.content ? data.content.replace(/<img/g, '<img style=width:100%;height:auto; ') : ''"></div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            data: {
                createTime: '',
                readCount: 0,
            },
			nav: [
			    {
			        title: '首页',
			        to: { path: '/' },
			    },
			    {
			        title: '志愿服务',
			    },
			    {
			        title: '志愿者风采',
			        to: { path: '/volunteerList' },
			    },
				{
					title: '详情',
				},
			]
        };
    },
    created() {
        console.log(this.$route.query.id);
        this.$hao.getvolunteerServiceDetail(this.$route.query.id).then((res) => {
            this.data = res.data;
        });
    },
    methods: {},
};
</script>

<style scoped lang="scss">
.title {
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    padding: 20px 0;
    font-weight: 700;
    font-size: 32px;
}
.time {
    display: flex;
    justify-content: space-between;
    margin: 20px 0 40px 0;
    font-size: 17px;
    color: rgba(0, 0, 0, 0.5);
}
</style>
